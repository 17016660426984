const lang = {
    en: {
        search : "Search",
        gptSearchPlaceholder: "What would you like to watch today? "
    },
    hi: {
        search:"खोज",
        gptSearchPlaceholder: "आज आप क्या देखना चाहेंगे?",
    },
    spanish: {
        search:"Buscar",
        gptSearchPlaceholder: "¿Qué te gustaría ver hoy?",
    }
}


export default lang;