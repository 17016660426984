import React from 'react'
import Header from './Header'
import DeveloperCard from './DeveloperCard'

const Developer = () => {
  return (
    <div>
        <Header/>
        {/* <DeveloperCard/> */}
      
    </div>
  )
}

export default Developer
